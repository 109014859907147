import _ from "lodash";
import Highway from '@dogstudio/highway';
import gsap from 'gsap';
import { Tween } from "gsap/gsap-core";

class transition extends Highway.Transition {
    in ({from, to, done}) {
        from.remove();
        Tween.to('.header', {opacity: 1,
            onComplete: window.scrollTo(0,0),
            done
        });
    }
    out ({from, done}) {
        Tween.fromTo(from, 0.25, {opacity: 1}, {opacity: 0, onComplete: done});
        Tween.to('.header', {opacity: 0});
    }
}

export default transition;