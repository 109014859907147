import _ from "lodash";
import LocomotiveScroll from 'locomotive-scroll';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import Highway from '@dogstudio/highway';

class postRenderer extends Highway.Renderer {
    onEnter() {

        // Locomotive Scroll Init

        const scroll = new LocomotiveScroll({
            lenisOptions: {
                smoothWheel: true,
                normalizeWheel: true,
                wheelMultiplier: .6
            }
        });

        // Local Time

        function updateTime() {
            const now = new Date();
            const timeString = now.toLocaleTimeString();
            document.getElementById('time').textContent = timeString;
        }
        
        updateTime();
        setInterval(updateTime, 1000); 

        // Post Intro

        const postTimeline = gsap.timeline ({
            onComplete: function() {
                gsap.set("a", { transition: '.5s' });
            }
        });

        gsap.set(".logo-eclipse", { y: 75, opacity: 0 });
        gsap.set(".preLoadCover h3 span", { visibility: 'visible' });
        gsap.set(".preLoadCover", { y: 0, display: "block" });
        gsap.set("a", { transition: 'none' });

        postTimeline.from('.preLoadCover h3 span', {
            y: 75,
            duration: 1,
            ease: "circ.out",
            stagger: .15
        });
        postTimeline.to('.preLoader', {
            duration: 1.75,
            ease: "circ.out",
            opacity: 0
        });
        postTimeline.to('.preLoadCover h3 span', {
            y: -30,
            duration: 1.5,
            ease: "circ.out"
        }, "-=1");
        postTimeline.to('.preLoadCover', {
            y: -1200,
            ease: "power4.in",
            duration: 1
        }, "-=1.3");
        postTimeline.from('.header-post', {
            y: 75,
            opacity: 0,
            ease: "circ.out",
        }, "-=0.2");
        postTimeline.to('.logo-eclipse', {
            y: 0,
            opacity: 1,
            ease: "circ.out",
            duration: .2
        }, "-=0.5");
        postTimeline.from('.header__left div p', {
            y: 75,
            opacity: 0,
            ease: "circ.out",
            stagger: .05
        },"-=0.5");
        postTimeline.from('.link-primary__contact', {
            y: 75,
            opacity: 0,
            ease: "circ.out"
        },"-=0.5");
        postTimeline.from('.header__right a', {
            y: 75,
            opacity: 0,
            ease: "circ.out",
            stagger: .05
        }, "-=0.2");
        postTimeline.from('.fade-intro', {
            y: 75,
            opacity: 0,
            ease: "circ.out",
            stagger: .05
        }, "-=0.2");
        postTimeline.from('.fade-section', {
            y: 75,
            opacity: 0,
            ease: "circ.out",
            stagger: .025
        }, "-=0.2");
        postTimeline.timeScale(1.75);

        /* Post Body Delay Animations

        const sections = document.querySelectorAll(".fade-section");

        sections.forEach((section) => {
            gsap.fromTo(section, {
                y: 25,
                opacity: 0,
                ease: "circ.out"
            }, {
                y: 0,
                opacity: 1,
                ease: "circ.out"
            });
        });*/

        // Locomotive Scroll to Top

        function scrollTo(params) {
            const { target, options } = params;
            scroll.scrollTo(target, options);
        }

        const scrollToTop = document.getElementById('scrollToTop');

        scrollToTop.addEventListener('click', (event) => {
            scrollTo({ target: 0, options: {
                duration: 3.5
            }});
        });

        // Prevent Autoplay on Mobile

        window.onLoad = function() {
            const screenWidth = $(window).width();
            if (screenWidth >= 800) {
            $('#reelisterVideo').attr('autoplay', 'autoplay');
            }
        };
    }
    onEnterCompleted() {   
    }
}

export default postRenderer;