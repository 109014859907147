import _ from "lodash";
import LocomotiveScroll from 'locomotive-scroll';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import Highway from '@dogstudio/highway';

class homeRenderer extends Highway.Renderer {
    onEnter() {

        // Locomotive Scroll Init
        
        const scroll = new LocomotiveScroll({
            lenisOptions: {
                smoothWheel: true,
                normalizeWheel: true,
                wheelMultiplier: .6
            }
        });

        // Locomotive Scroll to Work

        const recentProjects = document.getElementById('recent-projects');
        const workTrigger = document.getElementById('workLink');

        function scrollTo(params) {
            const { target, options } = params;
            scroll.scrollTo(target, options);
        }

        workTrigger.addEventListener('click', (event) => {
            scrollTo({ target: recentProjects, options: {
                offset: -30,
                duration: 2
            }});
        });

        // Locomotive Scroll to Top

        const scrollToTop = document.getElementById('scrollToTop');

        scrollToTop.addEventListener('click', (event) => {
            scrollTo({ target: 0, options: {
                duration: 3.5
            }});
        });

        // Local Time

        function updateTime() {
            const now = new Date();
            const timeString = now.toLocaleTimeString();
            document.getElementById('time').textContent = timeString;
        }
        
        updateTime();
        setInterval(updateTime, 1000);

        // Landing Intro

        const landingTimeline = gsap.timeline ({
            onComplete: function() {
                gsap.set("a", { transition: '.5s' });
            }
        });

        gsap.set(".logo-eclipse", { y: 75, opacity: 0 });
        gsap.set(".preLoadCover h3 span", { visibility: 'visible' });
        gsap.set(".preLoadCover", { y: 0, display: "block" });
        gsap.set("a", { transition: 'none' });

        landingTimeline.from('.preLoadCover h3 span', {
            y: 75,
            duration: 1,
            ease: "circ.out",
            stagger: .2
        });
        landingTimeline.to('.preLoader', {
            duration: 1.75,
            ease: "circ.out",
            opacity: 0
        });
        landingTimeline.to('.preLoadCover h3 span', {
            y: -30,
            duration: 1.5,
            ease: "circ.out"
        }, "-=1");
        landingTimeline.to('.preLoadCover', {
            y: -1200,
            ease: "power4.in",
            duration: 1
        }, "-=1.3");
        landingTimeline.from('.header-main span', {
            y: 75,
            opacity: 0,
            ease: "circ.out",
            stagger: .25
        });
        landingTimeline.to('.logo-eclipse', {
            y: 0,
            opacity: 1,
            ease: "circ.out",
            duration: .2
        }, "-=0.5");
        landingTimeline.from('.header__left div p', {
            y: 75,
            opacity: 0,
            ease: "circ.out",
            stagger: .05
        },"-=0.5");
        landingTimeline.from('.link-primary__contact', {
            y: 75,
            opacity: 0,
            ease: "circ.out"
        },"-=0.5");
        landingTimeline.from('.header__right a', {
            y: 75,
            opacity: 0,
            ease: "circ.out",
            stagger: .05
        }, "-=0.2");
        landingTimeline.from('.part .part__section', {
            y: 75,
            opacity: 0,
            ease: "circ.out"
        });
        landingTimeline.from('.body-nav__wrapper', {
            y: 75,
            opacity: 0,
            ease: "circ.out",
            stagger: .05
        });
        landingTimeline.timeScale(1.75);
        
        // First Section

        const firstSectionTimeline = gsap.timeline ({
            scrollTrigger: {
                trigger: '#recent-projects',
                start: 'top 800px'
            }
        });

        gsap.set("#reelisterProjectLink", { opacity: 0 });

        firstSectionTimeline.from('#recent-projects',{
            transformOrigin: 'left', 
            width: '0%' ,
            ease: "circ.out",
            duration: .75
        });
        firstSectionTimeline.from('#recent-projects-span',{
            y:25,
            ease: "circ.out"
        });
        firstSectionTimeline.from('#reelisterCover',{
            y: 75, 
            opacity: 0, 
            ease: "circ.out"
        }, "-=0.25");
        firstSectionTimeline.from('#reelisterSection div',{
            y: 75,
            opacity: 0,
            stagger: .05, 
            ease: "circ.out"
        });
        firstSectionTimeline.to('#reelisterProjectLink',{
            opacity: 1,
            ease: "circ.out"
        }, "-=0.5");
        firstSectionTimeline.timeScale(1.25);

        // Second Section

        const secondSectionTimeline = gsap.timeline ({
            scrollTrigger: {
                trigger: '#archive',
                start: 'top 800px'
            }
        });

        gsap.set("#metroProjectLink", { opacity: 0 });

        secondSectionTimeline.fromTo('#archive',{
            transformOrigin: 'left', 
            width: '0%',
            ease: "circ.out",
            duration: .75
        }, {
            width: '100%'
        });
        secondSectionTimeline.from('#archive-span',{
            y:25,
            ease: "circ.out"
        });
        secondSectionTimeline.from('#metroCover',{
            y: 75, 
            opacity: 0, 
            ease: "circ.out"
        });
        secondSectionTimeline.from('#metroSection div',{
            y: 75,
            opacity: 0,
            stagger: .05, 
            ease: "circ.out"
        });
        secondSectionTimeline.to('#metroProjectLink',{
            opacity: 1,
            ease: "circ.out"
        }, "-=0.5");
        secondSectionTimeline.timeScale(1.5);


        // Third Section

        const thirdSectionTimeline = gsap.timeline ({
            scrollTrigger: {
                trigger: '#rbgTrigger',
                start: 'top 800px'
            }
        });

        gsap.set("#rbgProjectLink", { opacity: 0 });

        thirdSectionTimeline.from('#rbgCover',{
            y: 75, 
            opacity: 0, 
            ease: "circ.out"
        });
        thirdSectionTimeline.from('#rbgSection div',{
            y: 75,
            opacity: 0,
            stagger: .05, 
            ease: "circ.out"
        });
        thirdSectionTimeline.to('#rbgProjectLink',{
            opacity: 1,
            ease: "circ.out"
        }, "-=0.5");

        // Fourth Section

        var fourthSectionTimeline = gsap.timeline ({
            scrollTrigger: {
                trigger: '#clientsTrigger',
                start: 'top 800px'
            }
        });

        fourthSectionTimeline.from('#clientsSection div',{
            y: 75,
            opacity: 0,
            stagger: .05, 
            ease: "circ.out"
        });
    }
    onEnterCompleted () {
    }
}

export default homeRenderer;