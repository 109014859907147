import _ from "lodash";
import Highway from '@dogstudio/highway';
import transition from './transition';
import homeRenderer from './homeRenderer';
import postRenderer from './postRenderer';
import gsap from 'gsap';

// Highway Core Init

const H = new Highway.Core({
    transitions: {
        default: transition
    },
    renderers: {
        home: homeRenderer,
        post: postRenderer
    }
});

// Copyright

console.log('All content © Lu Cordero 2025 & beyond unless otherwise stated.');